import styled, { css } from 'styled-components';

import { only, up } from 'styled-breakpoints';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { em, rem } from 'polished';

import _Hr from 'src/components/Hr';

import _Skeleton from 'src/components/Skeleton';
import _ProductTile from 'src/components/ProductTile';
import _ArrowBig from 'src/components/Icons/ArrowBig';

const DEFAULT_MARGIN = 48;
const DEFAULT_MARGIN_FROM_LISTING = 25;

const createStyleForVerticalVariant = () => css`
  padding: 0;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : em(DEFAULT_MARGIN))};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : em(DEFAULT_MARGIN))};
`;
const createStyleForHorizontalVariant = () => css`
  padding: 0 ${em(DEFAULT_MARGIN)};
  padding: ${({ fromListing }) => (!fromListing ? `0 ${em(DEFAULT_MARGIN)}` : `0 ${em(DEFAULT_MARGIN_FROM_LISTING)}`)};

  ${({ marginTop }) => `margin-top: ${marginTop}px;`}
  ${({ marginBottom }) => `margin-bottom: ${marginBottom}px;`}
`;

const createStylesForVerticalOnTablet = () => css`
  padding: 0;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : em(DEFAULT_MARGIN))};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : em(DEFAULT_MARGIN))};
`;

export const ArrowBig = styled(_ArrowBig)`
  position: absolute;

  z-index: 5;
  padding: 0;
  cursor: pointer;

  ${({ direction }) => {
    switch (direction) {
      case 'up':
        return css`
          top: -0.75rem;
        `;
      case 'down':
        return css`
          bottom: -0.75rem;
        `;
      case 'left':
        return css`
          left: 0;
        `;
      case 'right':
        return css`
          right: 0;
        `;
      default:
        return '';
    }
  }}
`;

export const CarouselOfProductsFromQuery = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ marginTop }) => `margin-top: ${marginTop}px;`}
  ${({ marginBottom }) => `margin-bottom: ${marginBottom}px;`}
  
  ${({ fromListing }) => !fromListing && `margin-right: ${rem(-16)}`};
  ${({ fromListing }) => !fromListing && `margin-left: ${rem(-16)}`};

  padding: 0;

  font-size: 10px;
  ${up(breakpointsKeys.TABLET)} {
    margin-left: unset;
    margin-right: unset;
    
    ${({ fromListing }) => fromListing && `padding: 0 ${rem(1)}`};
    ${({ shouldDisplayVerticalOnTablet }) => shouldDisplayVerticalOnTablet && createStylesForVerticalOnTablet()};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    ${({ verticalDirection }) =>
      verticalDirection ? createStyleForVerticalVariant() : createStyleForHorizontalVariant()}
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 1132px;

  .swiper-container {
    width: 100%;
    ${only(breakpointsKeys.MOBILE)} {
      ${({ fromListing }) => !fromListing && 'right: 10px; padding-left: 15px; width: 102%;'}
    }
  }

  ${({ verticalDirection, slideToShow, spaceBetweenSlide }) =>
    verticalDirection
      ? css`
          height: ${(251 + spaceBetweenSlide) * slideToShow}px;
          max-height: 100%;

          .swiper-container {
            height: 100%;
          }
        `
      : null}

  .swiper-slide {
    text-align: center;
    background: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${up(breakpointsKeys.DESKTOP)} {
      ${({ fromListing }) =>
        fromListing &&
        css`
          transition: box-shadow 0.3s;
          &:hover {
            box-shadow: 7px 8px 27px 0px rgba(0, 0, 0, 0.1);
          }
          margin-bottom: 20px;
        `}
    }
  }
`;

export const ProductTile = styled(_ProductTile)`
  margin-bottom: 0;
  border-radius: 15px;
`;

export const SkeletonLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  padding: ${rem(5)};
  align-items: center;
  border-radius: ${rem(15)};
  flex: 1;
  gap: 3px;
`;
export const SkeletonsLoaderWrapper = styled.div`
  display: flex;
  overflow: hidden;
  /* flex-direction: column; */
  max-width: 1132px;
  width: 120%;
  gap: 5px;
  ${up(breakpointsKeys.TABLET)} {
    width: 115%;
    max-width: ${({ fromListing }) => !fromListing && 'unset'};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    width: 100%;
    padding: 0 ${rem(15)};
  }
`;
export const SkeletonsLoaderWrapperVertical = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  max-width: 1130px;
  gap: 10px;
`;
export const SkeletonLoaderPriceSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 3px;
`;

export const SkeletonLoaderImgSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddToBasketSkeletonSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AddToBasketButtonSkeleton = styled(_Skeleton)`
  border-radius: ${rem(25)};
`;

export const SkeletonGroupWrapper = styled.div`
  overflow: hidden;
  ${up(breakpointsKeys.DESKTOP)} {
    margin-bottom: 10px;
  }
`;

export const Hr = styled(_Hr)`
  margin: 0 ${rem(-16)};
  height: ${rem(2)};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[1]};

  ${up(breakpointsKeys.TABLET)} {
    margin: ${rem(40)} 0;
    height: ${rem(1)};
  }
`;
