import Skeleton from 'src/components/Skeleton';

import * as S from './styles';

export const SkeletonLoader = () => {
  return (
    <S.SkeletonLoaderWrapper>
      <Skeleton height={100} width="70%" variant="rounded" />
      <Skeleton height={60} variant="rounded" />
      <Skeleton height={20} variant="rounded" />
      <S.SkeletonLoaderPriceSection>
        <Skeleton height={25} width="60%" variant="rounded" />

        <Skeleton height={16} width={35} variant="rounded" />
        <S.AddToBasketSkeletonSection>
          <Skeleton height={25} width={75} variant="rounded" />
          <S.AddToBasketButtonSkeleton height={25} width={50} variant="rounded" />
        </S.AddToBasketSkeletonSection>
      </S.SkeletonLoaderPriceSection>
    </S.SkeletonLoaderWrapper>
  );
};
