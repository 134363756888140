export const mapRecommendationsToOfferCarousel = (recommendations) => {
  return recommendations.map((recommendation) => {
    const quantity = recommendation?.avail?.quantitydc || 0;

    const firstItemBrand = recommendation?.brand?.[0];
    const firstItemCategories = recommendation?.categories?.[0];

    return {
      slug: recommendation?.slug || '',
      name: recommendation?.name || '',
      price: recommendation?.price?.items ? recommendation.price.items[0].priceend : 0,
      priceSign: recommendation?.price?.currsig || '',
      pricingValueVariants:
        recommendation?.price?.items.map((priceObj) => ({
          price: priceObj.priceend || null,
          crossedPrice: priceObj?.pricecross ? priceObj?.pricecross : null,
          unit: recommendation?.unit || null,
          amount: priceObj.quantity || 1,
          seoPrice: priceObj?.priceseo || null,
          customPriceColor: recommendation?.price?.promo || null,
          lastPrice: priceObj.lastprice || '',
        })) || [],
      imgUrl: recommendation?.imageth || '',
      largeImgUrl: recommendation?.imagemd || '',
      stock: {
        value: quantity,
        unit: recommendation?.unit || '',
        desc: quantity > 0 ? null : recommendation?.avail?.description,
      },
      rid: recommendation?.rid || '',
      productId: recommendation?.id || -1,
      isFullPackages: recommendation?.price?.fullPackages || null,
      nowInBasket: recommendation?.avail?.quantityinbasket || 0,
      isPartial: recommendation?.ispartial || false,
      stepQuantityProduct: recommendation?.packages?.[0] || 1,
      categoryName: firstItemCategories?.name || null,
      onninenIndex: recommendation?.index || null,
      onntop: recommendation?.onntop || 0,
      onntopcb: recommendation?.onntopcb || 0,
      producerName: firstItemBrand?.name,
      availability: {
        unit: recommendation?.unit,
        showAskForm: recommendation?.avail?.showask,
        mainVolume: recommendation?.avail?.quantitydc,
        conditionIndex: recommendation?.avail?.availstatus,
        conditionLabel: recommendation?.avail?.description,
        localVolume: recommendation?.avail?.quantitylc > 0 ? recommendation.avail.quantitylc : null,
      },
    };
  });
};
